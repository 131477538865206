<template>
  <div class="position-relative">
    <div class="bg-light">
      <div class="container py-5">
        <div class="row h-100 align-items-center py-5">
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
            <h3 class="display-4 font-weight-bold text-white mt-5">About Mimo</h3>
            <p class="lead text-white mb-5">
             We have been a trusted shipping service in Indonesia since 2015
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="py-5">
      <div class="container py-5">
        <div class="container">
        <div class="row">
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <img
              src="@/assets/images/pexels-frans-van-heerden-2881632.jpg"
              class="img-about d-none d-md-block"
              alt=""
            />
          </div>
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          >
            <div class="">
              <div class="text-left">
                <h1 class="f-bold cl-primary">Mimo Logistics</h1>
                <p class="cl-secondary">
                  <span class="font-weight-bold"> MiMo Logistics</span> is an export import shipping service from China to Indonesia. We provide a complete range of services, from shipping and customs clearance to warehousing and distribution. We have a team of experienced professionals who are dedicated to providing the best possible service to our customers. We believe in offering quality services at competitive prices, and we are committed to providing the highest level of customer satisfaction. With the secure storage of money before the goods have been delivered to the customer. We make sure that you have received the product properly and without any damage before we release the funds to the merchant. We are a reliable and trustworthy company that wants to make sure that you are fully satisfied with your purchase.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <div class="my-5">
      <div class="container mt-5">
        <div class="row">
          <div
            class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          >
            <div class="">
              <div class="card flex-row text-left mb-5">
                <img class="card-img-left example-card-img-responsive" src="@/assets/images/target.png" width="100" height="100"/>
                <div class="card-body text-start p-3">
                  <h4 class="card-title h5 h4-sm">Vission</h4>
                  <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsum doloribus, quis </p>
                </div>
              </div>
              <div class="card flex-row text-left">
                <img class="card-img-left example-card-img-responsive" src="@/assets/images/mission.png" width="100" height="100"/>
                <div class="card-body text-start">
                  <h4 class="card-title h5 h4-sm">Mission</h4>
                  <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. At ipsum doloribus, quis </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <img
              src="@/assets/images/pexels-martin-damboldt-799091.jpg"
              class="img-about d-none d-md-block"
              alt=""
            />
          </div>
          
        </div>
      </div>
    </div>

    <div class="m5-5">
      <div class="bg-light-no py-5">
        <img class="card-img-left example-card-img-responsive" src="@/assets/images/review.png" width="100" height="100"/>
        <h3 class="text-white font-weight-bold mt-4">"We have more than 7 years of experience" </h3>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
};
</script>
